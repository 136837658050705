import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/flayout"
import HelpCategoryPage from "../components/helpCategoryPage"

export class HelpCategory extends React.Component {

  render() {
    const category = this.props.data.sanityHelpCategory;
    const topics = this.props.data.allSanityHelpTopic.edges;
    console.log(category,topics,'helpcategory')
    return (
      <Layout darkHeader={true} seoFields={{...seoFields,title:category.title}} headerFixed={false} showAnnouncement={false}>
        <HelpCategoryPage category={category} topics={topics}/>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
query sanityHelpCategoryQuery($categoryId: String!){
  sanityHelpCategory(id: { eq: $categoryId}){
    title,
    slug
  }
  allSanityHelpTopic(
    filter:{
      category:{id:{eq:$categoryId}}
      hideFromCategoryPage:{ne:true}
    }
    sort:{
      fields:[categoryPageOrder,title]
      order:[ASC,ASC]
    }
  ) {
    edges {
      node {
        title
        slug
        categoryPageTitle
        categoryPageOrder
      }
    }
  }
}
`

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  title: "Help Category" 
}

export default HelpCategory;