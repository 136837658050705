import React from "react"
import {  Link } from "gatsby"

import styles from "./styles.module.css";

import { getHelpTopicPath } from '../../gFunctions';

const HelpCategoryPage = ({category,topics}) => (
  <>
    <div>
        <div className={[styles.top_banner_section,'d-flex','justify-content-center'].join(' ')}>
            <div className={['align-self-end', 'w-100'].join(' ')}>
                <div className="align-self-stretch">
                    <div className="container">
                        <div className={["row", "justify-content-md-center"].join(" ")}>
                            <div className={[styles.mpr_main_title ,'col-md-12', 'mb-5', 'pb-5'].join(' ')}>
                                <h2>{category.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.helpTopicsListContainer}>
            <div className="container">
                <div className={["col-md-12",styles.helpTopicTitle, "mb-5"].join(" ")}>Helpful Links</div>
                <div className={["col-md-12", "row", "justify-content-md-start",styles.helpTopicsList].join(" ")}>
                    {
                        topics.map((topic,index) => {
                            return (
                            <div key={index} className={["col-lg-4","col-md-6","col-xs-12","d-flex","justify-content-start","align-items-center","mb-4",styles.helpTopicItem].join(" ")}>
                                <span className={[styles.bullet,"flex-shrink-0"].join(" ")}></span>
                                <Link className={styles.bulletText} to={getHelpTopicPath(topic.node.slug,category.slug)}>{topic.node.categoryPageTitle || topic.node.title}</Link>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  </>
)

export default HelpCategoryPage
